import {
    actionsOptica as actions
} from "./types";

export const setOptica = (optica: any) => {
    return {
        type: actions.SET_OPTICA,
        payload: optica,
    };
};

export const removeOptica = () => {
    return {
        type: actions.REMOVE_OPTICA
    };
};