import { TranslateDto } from "../translate.dto";

export const it: TranslateDto = {
  // Not found Page
  Not_found_page: "404: Pagina non trovata.",
  //Global
  Go_back: "Torna all'inizio",
  // Topbar
  Slogan: "L'unico occhiale completamente personalizzabile al 100%",
  // PutPostCode
  Error_PERMISSION_DENIED:
    "L'unico occhiale completamente personalizzabile al 100%.",
  Error_POSITION_UNAVAILABLE:
    "Le informazioni sulla posizione non sono disponibili.",
  Error_TIMEOUT: "Il tempo di richiesta è scaduto",
  Error_UNKNOWN_ERROR: "Si è verificato un errore sconosciuto.",
  Error_default: "Si è verificato un errore sconosciuto.",
  Search_your_optic: "Cerca il tuo ottico più vicino.",
  Description_for_search_optic:
    "Inserisci il tuo indirizzo e cercheremo gli ottici più vicini a te 👇.",
  Alert: "Sembra che ci sia un problema.",
  Placeholder_search_optic: "Inserisci il tuo indirizzo o il codice postale",
  Can_I_Have_Your_Ubication: "Posso avere la tua posizione?",
  Description_We_Need_Ubication:
    "Per trovare l'ottico più vicino a te, dovrai permettermi di accedere alla tua posizione.",
  Confirm: "Sì, certo!",
  Denaied: "No, grazie",
  Cant_Accesses_Your_Ubication:
    "Mi dispiace, non posso accedere alla tua posizione.",
  Description_Cant_Accesses_Your_Ubication:
    "Sembra che tu abbia negato l'accesso alla tua posizione. Per trovare l'ottico più vicino a te, dovrai inserire il tuo indirizzo o il codice postale nel campo sopra",
  Accept: "D'accordo",
  Search_GPS: "Ricerca GPS...",
  Use_My_Location: "Usa la mia posizione attuale",
  No_Selection_Location: "Non hai selezionato alcun indirizzo",
  Search: "Cerca",
  Ofert1: "Ottieni uno sconto del 25% + esame visivo gratuito solo per prenotare un appuntamento",
  // Ubication
  Go_to_the_list: "Torna alla lista",
  This_is_your_optics: "Questi sono i tuoi ottici più vicini:",
  Description_We_Need_Ubication2:
    "Inserisci il tuo indirizzo nel campo di ricerca in alto per trovare l'ottico più vicino a te",
  Not_Found_Optics: "Ops...! Non ci sono ottici vicino a te.",
  Form_to_contact: "Puoi anche consultare con noi per",
  Distance: " Chilometri di distanza",
  Ask_date: "PRENOTA UN APPUNTAMENTO.",
  // CardOptic
  Optic_nearest: "Questo è il tuo punto vendita più vicino.",
  Of_distance: " di distanza",
  See_map: "Visualizza mappa",
  // Schedule
  Ofert2:
    "Prenota un appuntamento e ottieni uno sconto del 25% e un esame visivo gratuito!",
  Choose_optic: "Ottica scelta:",
  Direction: "Indirizzo:",
  Selected_day: "Seleziona un giorno:",
  Choose_hour: "Scegli un orario per essere assistito:",
  Load_more_days: "Mostra più giorni",
  No_search_schedules: "Ops...! Non ci sono appuntamenti disponibili.",
  Change_optic: "Cambiare ottico.",
  See_more: "Vedi di più",
  Try_another_week: "Puoi anche provare un'altra settimana",
  // Hours Container
  No_schedule: "Non ci sono più prenotazioni disponibili",
  // Client Data
  Form_title: "Compila i tuoi dati per prenotare l'appuntamento",
  Receive_ofert: "Riceverai la tua offerta via email e WhatsApp",
  Your_choose_optic: "La tua ottica scelta:",
  Your_choose_date: "La tua data scelta:",
  Name: "Nome",
  Subname: "Cognome",
  Phone: "Numero di telefono.",
  Email: "Indirizzo email",
  Incorrect_field: "Campo errato *",
  Next: "Successivo",
  // Confirmation
  Error_create_appointment:
    "Si è verificato un errore durante la creazione dell'appuntamento",
  Error_update_appointment:
    "Si è verificato un errore durante l'aggiornamento dell'appuntamento.",
  Edit_your_data: "Modifica i tuoi dati per cambiare l'appuntamento.",
  Confirm_for_reserve: "Conferma i tuoi dati per prenotare l'appuntamento",
  Change_my_optic: "Cambiare il mio ottico",
  Change_my_date: "Cambiare la mia data",
  changeMyData: "Cambia i miei dati",
  Your_data: "I tuoi dati di contatto:",
  Confirm_appointment: "Confermare l'appuntamento.",
  Edit_my_data: "Modificare i miei dati.",
  Greetings: "Ciao!",
  Of: "de",
  Await_you: "ti aspetterà il giorno.",
  At: "alle",
  If_put: "Se indossi le lenti a contatto.",
  No_24h: "non metterle per 24 ore.",
  Pre_exam: "prima dell'esame",
  Is_easy: "È molto facile arrivare a:",
  Benefit_appointment_title: "Durante l'appuntamento:",
  Benefit_appointment_1: "Effettuiamo una revisione completamente gratuita.",
  Benefit_appointment_2:
    "Potrai provare una vasta selezione di colori e accessori.",
  Benefit_appointment_3:
    "Verificherai quali dei nostri modelli si adattano meglio al tuo viso",
  Benefit_appointment_4:
    "Potrai consultare con il tuo ottico quali clips hai bisogno.",
  Free: "Tutto in modo gratuito!",
  Remember:
    "Ricorda che con il tuo appuntamento prenotato non dovrai fare la fila, inoltre, solo per partecipare potrai accedere a",
  Promotion: "promozione",
  No_assist:
    "In caso non potessi partecipare o avessi qualsiasi dubbio, puoi contattarci tramite",
  Or: "o",
  Start: "Inizio",
  Until_the: "Fino a quando",
  Question_1: "Di cosa sono fatti gli occhiali FLiPO?",
  Answer_1:
    "Tutti i nostri prodotti sono realizzati con un materiale all'avanguardia per flessibilità e resistenza, noto come ULTEM. Per maggiori informazioni è possibile consultare la scheda tecnica di ogni modello di occhiali 🤓🔛😎👍",
  Question_2: "Come funzionano le clip e le aste?",
  Answer_2:
    "Le clip e le aste funzionano grazie a un sistema magnetico 🧲 che le rende facili e sicure da applicare senza il timore che si stacchino. 🤓🔛😎🙌",
  Question_3:
    "Qual è la differenza tra la clip funzionale e la clip di design?",
  Answer_3:
    "La clip funzionale 👓 combina l'aiuto visivo delle lenti da vista e, grazie alle diverse funzionalità, trasforma gli occhiali e protegge dalla luce blu, dal sole e da altri raggi che possono compromettere la vista 🤓. La clip di design, invece, cambia l'aspetto del frontale 😎 della montatura e, insieme alla sostituzione delle aste, cambia completamente lo stile degli occhiali 🤓🔛😎🙌",
  Question_4: "Quando saranno pronti i miei occhiali monofocali FLiPO?",
  Answer_4: "I vostri occhiali saranno pronti in 7-10 giorni lavorativi",
  Question_5: "Quando saranno pronti i miei occhiali progressivi FLiPO?",
  Answer_5:
    "I vostri occhiali saranno pronti in 7-10 giorni lavorativi - vale la pena aspettare! ⚡️",
};
