// Spanish translation file for the i18n module
import { TranslateDto } from "../translate.dto";

export const es: TranslateDto = {
  // Not found Page
  Not_found_page: "404: Pagina no encontrada",
  //Global
  Go_back: "Volver al inicio",
  // Topbar
  Slogan: "La única gafa 100% personalizable",
  // PutPostCode
  Error_PERMISSION_DENIED:
    "El usuario ha denegado el permiso para la geolocalización.",
  Error_POSITION_UNAVAILABLE:
    "La información de la localización no está disponible.",
  Error_TIMEOUT: "El tiempo de petición ha expirado.",
  Error_UNKNOWN_ERROR: "Ha ocurrido un error desconocido.",
  Error_default: "Ha ocurrido un error desconocido.",
  Search_your_optic: "Busca tu óptica más cercana",
  Description_for_search_optic:
    "Introduce tu dirección y buscaremos las ópticas más cercanas a ti 👇",
  Alert: "Parece que hay algo mal",
  Placeholder_search_optic: "Escribe tu dirección o código postal",
  Can_I_Have_Your_Ubication: "¿Puedo tener tu ubicación?",
  Description_We_Need_Ubication:
    "Para encontrar la óptica más cercana a ti deberás permitirme acceder a tu ubicación",
  Confirm: "Sí, claro!",
  Denaied: "No, gracias",
  Cant_Accesses_Your_Ubication: "Lo siento, no puedo acceder a tu ubicación",
  Description_Cant_Accesses_Your_Ubication:
    "Parece que has denegado el acceso a tu ubicación. Para encontrar la óptica más cercana a ti deberás escribir tu dirección o código postal en el campo de arriba",
  Accept: "De acuerdo",
  Search_GPS: "Buscando GPS...",
  Use_My_Location: "Usa mi ubicación actual",
  No_Selection_Location: "No has seleccionado ninguna dirección",
  Search: "Buscar",
  Ofert1: "Obtén un 25% de descuento + examen visual gratuito solo por pedir cita",
  // Ubication
  Go_to_the_list: "Volver a la lista",
  This_is_your_optics: "Estas son tus ópticas más cercanas:",
  Description_We_Need_Ubication2:
    "Introduce tu dirección en el buscador de arriba para ver tu óptica más cercana",
  Not_Found_Optics: "¡Ups...! No hay ópticas cerca de ti",
  Form_to_contact: "También puedes consultarnos por",
  Distance: " Km de distancia",
  Ask_date: "PEDIR CITA",
  // CardOptic
  Optic_nearest: "Este es tu local más cercano",
  Of_distance: " de distancia",
  See_map: "Ver mapa",
  // Schedule
  Ofert2: "¡Pide cita y obtén un descuento y un examen visual gratuito!",
  Choose_optic: "Óptica elegida:",
  Direction: "Dirección:",
  Selected_day: "Selecciona un día:",
  Choose_hour: "Elige una hora para que te atiendan:",
  Load_more_days: "Cargar más días",
  No_search_schedules: "¡Ups...! No hay citas disponibles",
  Change_optic: "Cambiar de óptica",
  See_more: "Ver más",
  Try_another_week: "También puedes probar otra semana",
  // Hours Container
  No_schedule: "No quedan citas",
  // Client Data
  Form_title: "Rellena tus datos para reservar la cita",
  Receive_ofert: "Recibirás tu oferta por email y WhatsApp",
  Your_choose_optic: "Tú óptica elegida:",
  Your_choose_date: "Tú fecha elegida:",
  Name: "Nombre",
  Subname: "Apellido",
  Phone: "Teléfono",
  Email: "Email",
  Incorrect_field: "Campo incorrecto *",
  Next: "Siguiente",
  // Confirmation
  Error_create_appointment: "Hubo un error al crear la cita.",
  Error_update_appointment: "Hubo un error al actualizar la cita.",
  Edit_your_data: "Edita tus datos para cambiar la cita",
  Confirm_for_reserve: "Confirma tus datos para reservar la cita",
  Change_my_optic: "Cambiar mi óptica",
  Change_my_date: "Cambiar mi fecha",
  changeMyData: "Cambiar mis datos",
  Your_data: "Tus datos de contacto:",
  Confirm_appointment: "Confirmar cita",
  Edit_my_data: "Editar mis datos",
  Greetings: "¡Hola",
  Of: "de",
  Await_you: "te estará esperando el día",
  At: "a las",
  If_put: "Si llevas lentillas",
  No_24h: "no te las pongas durante las 24h",
  Pre_exam: "previas al examen",
  Is_easy: "Es muy fácil llegar a:",
  Benefit_appointment_title: "Durante la cita:",
  Benefit_appointment_1: "Revisamos de forma totalmente gratuita",
  Benefit_appointment_2:
    "Podrás probar una amplia selección de colores y accesorios",
  Benefit_appointment_3:
    "Comprobarás cuál de nuestros modelos se adaptan más a tu cara",
  Benefit_appointment_4: "Podrás consultar con tu óptico qué clips necesitas",
  Free: "¡Todo de forma gratuita!",
  Remember:
    "Recuerda que con tu cita reservada no tendrás que hacer cola, además, solo por asistir podrás acceder a la",
  Promotion: "promoción",
  No_assist:
    "En caso de que no puedas asistir o si tienes cualquier duda, puedes contactar con nosotros vía",
  Or: "o",
  Start: "Inicio",
  Until_the: "Hasta el",
  // FAQs
  Question_1: "¿De qué están hechas las gafas FLiPO?",
  Answer_1:
    "Todos nuestros productos están fabricados con un material pionero por su flexibilidad y resistencia, conocido como ULTEM. Para más información puedes consultar la ficha técnica de cada modelo de gafas 🤓🔛😎👍",
  Question_2: "¿Cómo funcionan los clips y las varillas?",
  Answer_2:
    "Los clips y varillas funcionan gracias a un sistema imantado 🧲 que hace que estos se puedan aplicar de una manera fácil y segura sin el miedo de que se vayan a desprender. 🤓🔛😎🙌",
  Question_3: "¿Qué diferencia hay entre el clip funcional y el de diseño?",
  Answer_3:
    "El clip funcional 👓 combina la ayuda visual de las lentes graduadas, y a través de las distintas funcionalidades transforma tu gafa, y protege de la luz azul, del sol y de los distintos rayos que pueden afectar a tu vista 🤓. En cambio, el clip de diseño cambia la apariencia del frontal 😎 de la montura y junto con el intercambio de varilla, consigue cambiar el estilo de la gafa por completo.🤓🔛😎🙌",
  Question_4: "¿Cuándo estarán listas mis FLiPO monofocales?​",
  Answer_4:
    "Tus gafas estarán graduadas en 7-10 días laborables. Y después, ¡a disfrutar!💥",
  Question_5: "¿Cuándo estarán listas mis FLiPO progresivas?",
  Answer_5:
    "Tus gafas estarán graduadas en 7-10 días laborables. ¡La espera vale la pena!⚡️",
};
