import {
    actionsUser as actions
} from "../actions/types";

    const userReducer = (state = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        postCode: '',
        idCustomer: '',
    }, 
    action: any) => {
    switch (action.type) {
        case actions.SET_USER:
            return action.payload;
        case actions.REMOVE_USER:
            return {
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                postCode: '',
                idCustomer: '',
            };
        default:
            return state;
    }
}

export default userReducer;