import { TranslateDto } from "../translate.dto";

export const uk: TranslateDto = {
  // Not found Page
  Not_found_page: "404: Page not found",
  //Global
  Go_back: "Go back to the start",
  // Topbar
  Slogan: "The only 100% customizable glasses",
  // PutPostCode
  Error_PERMISSION_DENIED: "The user has denied permission for geolocation.",
  Error_POSITION_UNAVAILABLE: "Location information is not available.",
  Error_TIMEOUT: "The request time has expired.",
  Error_UNKNOWN_ERROR: "An unknown error has occurred.",
  Error_default: "An unknown error has occurred..",
  Search_your_optic: "Find your nearest optician.",
  Description_for_search_optic:
    "Enter your address and we'll search for the closest opticians to you 👇",
  Alert: "It seems that something is wrong",
  Placeholder_search_optic: "Enter your address or postal code",
  Can_I_Have_Your_Ubication: "May I have your location?",
  Description_We_Need_Ubication:
    "To find the closest optician to you, you must allow me access to your location",
  Confirm: "Yes, of course!",
  Denaied: "No, thank you.",
  Cant_Accesses_Your_Ubication: "I'm sorry, I can't access your location",
  Description_Cant_Accesses_Your_Ubication:
    "It seems you have denied access to your location. To find the closest optician to you, you must enter your address or postal code in the field above",
  Accept: "Okay",
  Search_GPS: "Searching for GPS...",
  Use_My_Location: "Use my current location.",
  No_Selection_Location: "You have not selected any address.",
  Search: "Search",
  Ofert1:
    "Get a 25% discount + free visual exam just for making an appointment.",
  // Ubication
  Go_to_the_list: "Return to the list.",
  This_is_your_optics: "These are your nearest opticians:",
  Description_We_Need_Ubication2:
    "Enter your address in the search bar above to see your nearest optician",
  Not_Found_Optics: "Oops...! There are no opticians near you.",
  Form_to_contact: "You can also consult us by",
  Distance: " Kilometers away.",
  Ask_date: "MAKE AN APPOINTMENT",
  // CardOptic
  Optic_nearest: "This is your closest location",
  Of_distance: " away",
  See_map: "View map",
  // Schedule
  Ofert2: "Make an appointment and get a 25% discount and a free visual exam!",
  Choose_optic: "Chosen optician:",
  Direction: "Address:",
  Selected_day: "Select a day:",
  Choose_hour: "Choose a time for your appointment:",
  Load_more_days: "Load more days",
  No_search_schedules: "Oops...! There are no appointments available.",
  Change_optic: "Change optician.",
  See_more: "View more",
  Try_another_week: "You can also try another week",
  // Hours Container
  No_schedule: "No appointments available",
  // Client Data
  Form_title: "Fill in your details to book the appointment.",
  Receive_ofert: "You will receive your offer by email and WhatsApp.",
  Your_choose_optic: "Your chosen optician:",
  Your_choose_date: "Your chosen date:",
  Name: "Name",
  Subname: "Last name",
  Phone: "Telephone",
  Email: "Email address",
  Incorrect_field: "Invalid field *",
  Next: "Next",
  // Confirmation
  Error_create_appointment: "There was an error creating the appointment.",
  Error_update_appointment: "There was an error updating the appointment.",
  Edit_your_data: "Edit your information to change the appointment.",
  Confirm_for_reserve: "Confirm your information to book the appointment.",
  Change_my_optic: "Change my optician.",
  Change_my_date: "Change my date",
  changeMyData: "Change my information",
  Your_data: "Your contact details:",
  Confirm_appointment: "Confirm appointment.",
  Edit_my_data: "Edit my information",
  Greetings: "Hello!",
  Of: "of",
  Await_you: "will be waiting for you on the day.",
  At: "at",
  If_put: "If you wear contact lenses",
  No_24h: "do not wear them for 24 hours",
  Pre_exam: "before the exam",
  Is_easy: "It's very easy to get to:",
  Benefit_appointment_title: "During the appointment:",
  Benefit_appointment_1: "We review completely for free",
  Benefit_appointment_2:
    "You will be able to try out a wide selection of colors and accessories",
  Benefit_appointment_3:
    "You will check which of our models best suit your face.",
  Benefit_appointment_4:
    "You can consult with your optician about which clips you need",
  Free: "Everything for free!",
  Remember:
    "Remember that with your reserved appointment, you won't have to wait in line, and just by attending, you'll have access to the",
  Promotion: "offer",
  No_assist:
    "In case you can't attend or if you have any questions, you can contact us via",
  Or: "or",
  Start: "Start",
  Until_the: "Until",
  // FAQs
  Question_1: "What are FLiPO glasses made of?",
  Answer_1:
    "All our products are made of a pioneering material for its flexibility and resistance, known as ULTEM. For more information you can consult the technical data sheet of each model of glasses 🤓🔛😎👍",
  Question_2: "How do the clips and temples work?",
  Answer_2:
    "The clips and rods work thanks to a magnetic system 🧲 which makes them easy and safe to apply without the fear of them coming off. 🤓🔛😎🙌",
  Question_3:
    "What is the difference between the functional clip and the design clip?",
  Answer_3:
    "The functional clip 👓 combines the visual aid of prescription lenses, and through the different functionalities transforms your glasses, and protects from blue light, sun and different rays that can affect your eyesight 🤓. The design clip, on the other hand, changes the appearance of the front 😎 of the frame and, together with the temple exchange, completely changes the style of the glasses 🤓🔛😎🙌",
  Question_4: "When will my FLiPO single vision glasses be ready?",
  Answer_4:
    "Your glasses will be ready in 7-10 working days. After that, enjoy! 💥",
  Question_5: "When will my progressive FLiPO be ready?",
  Answer_5:
    "Your glasses will be ready in 7-10 working days - it´s worth the wait! ⚡️",
};
