import {
    actionsLead as actions
} from "./types";

export const setIdLead = (id: any) => {
    return {
        type: actions.SET_ID_LEAD,
        payload: id,
    };
};

export const removeIdLead = () => {
    return {
        type: actions.REMOVE_ID_LEAD
    };
};