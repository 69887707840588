import axios from "axios";
import { URL_API } from "../config";
import { CreateAppointmentDTO } from "./../services/DTOs_news/appointment/create_appointment.dto";
import { AppointmentPublicDTO } from "./../services/DTOs_news/appointment/appointment_public.DTO";

export const createAppointment = async (appointment: CreateAppointmentDTO) => {
  return await axios.post(`${URL_API}/appointments/public`, appointment);
};

export const updateAppointment = async (appointment: CreateAppointmentDTO) => {
  return await axios.put(
    `${URL_API}/appointments/public/${appointment.id}`,
    appointment
  );
};

export const getAppointment = async (
  id: string
): Promise<AppointmentPublicDTO> => {
  return (await axios.get(`${URL_API}/appointments/public/${id}`)).data;
};
