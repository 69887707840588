export const actionsOptica = {
    SET_OPTICA: 'SET_OPTICA',
    REMOVE_OPTICA: 'REMOVE_OPTICA',
}

export const actionsHorario = {
    SET_HORARIO: 'SET_HORARIO',
    REMOVE_HORARIO: 'REMOVE_HORARIO',
}

export const actionsUser = {
    SET_USER: 'SET_USER',
    REMOVE_USER: 'REMOVE_USER',
}

export const actionsLead = {
    SET_ID_LEAD: 'SET_ID_LEAD',
    REMOVE_ID_LEAD: 'REMOVE_ID_LEAD',
}

export const actionsAppointment = {
    SET_APPOINTMENT: 'SET_APPOINTMENT',
    REMOVE_APPOINTMENT: 'REMOVE_APPOINTMENT',
}

export const actionsEditMode = {
    SET_IS_EDIT_MODE: 'SET_IS_EDIT_MODE',
}

export const actionsLanguage = {
    SET_LANGUAGE: 'SET_LANGUAGE',
}

export const actionsCountry = {
    SET_COUNTRY: 'SET_COUNTRY',
}