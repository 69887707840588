import axios from "axios";
import { URL_API } from "../config";
import { DisponibilityDTO } from "./DTOs_news/optics/disponibility.dto";
import { ScheduleDTO } from "./DTOs_news/schedules/schedule.DTO";

const filters = {
  /**
   * If enabled, if the user is on the weekend, the first desired hours will be blocked from the start of the monday.
   */
  monday_block: {
    enabled: true,
    all_day: false,
    hours_to_block: 2,
  },

  /**
   * If enabled, if the user is outside the schedule of the optic, the next desired hours will be blocked from the start of the next schedule.
   */
  outside_schedule_block: {
    enabled: false,
    hours_to_block: 2,
  },

  /**
   * If enabled, we will block the next desired hours so the optic can prepare the appointment.
   */
  appointment_preparation_block: {
    enabled: true,
    hours_to_block: 2,
  },
};

export type DisponibleHour = {
  timestamp: number;
  redeable_hour: string;
};

export const getOptica = async (id: any) => {
  return await axios.get(`${URL_API}/optics/public/${id}`);
};

export const getOpticaByProximity = async (
  lat?: number,
  long?: number
): Promise<any> => {
  if (lat && long) {
    return await axios.get(`${URL_API}/optics/public/nearby/${lat}/${long}`);
  }
  // Coordinates of Madrid
  return await axios.get(
    `${URL_API}/optics/public/nearby/40.4165000/-3.7025600/`
  );
};

export const getDisponibility = async (
  id_optic: string,
  start_date?: number,
  end_date?: number
): Promise<any> => {
  let url = `${URL_API}/optics/public/disponibility/${id_optic}`;
  if (start_date) {
    url += `/${start_date}`;
  }
  if (end_date) {
    url += `/${end_date}`;
  }
  return await axios.get(url);
};

export const calculateDisponibility = (
  schedules: ScheduleDTO[],
  data: DisponibilityDTO,
  start_date?: Date,
  end_date?: Date
) => {
  const start = start_date ? start_date.getTime() : new Date().getTime();
  // If end_date is not defined, end_date will be the current date + 15 days
  const end = end_date
    ? end_date.getTime()
    : new Date(start).getTime() + 30 * 24 * 60 * 60 * 1000;
  //Create a map of available hours for each day
  const map = new Map<Date, DisponibleHour[]>(); // Map<date, DisponibleHour[]>

  // For each day between start_date and end_date
  for (let i = start; i <= end; i += 24 * 60 * 60 * 1000) {
    const date = new Date(i);
    //Set the date to 00:00:00
    date.setHours(0, 0, 0, 0);
    //create a copy of the date
    const date_copy = new Date(date);
    // Create an array of available hours for that day
    const available_hours: DisponibleHour[] = [];
    // Get the schedules for that day
    //Get day of the week
    const day_of_week = date.getDay() + 1;
    //Get array of the schedules for that day
    //make a copy of the schedules array
    const schedules_copy = [...schedules];
    const day_schedules = [
      ...schedules_copy.filter((s) => s.weekDay === day_of_week),
    ];
    //Order the schedules by start hour

    /**
     * outside_schedule_block filter
     */
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    //Check if date day is today
    if (
      filters &&
      filters.outside_schedule_block.enabled &&
      date.getTime() === today.getTime()
    ) {
      day_schedules.sort((a, b) => {
        const a_hour = parseInt(a.startDate.split(":")[0]);
        const b_hour = parseInt(b.startDate.split(":")[0]);
        return a_hour - b_hour;
      });

      //Check if now is inside one of the schedules.
      //If it is, add the the desired hours to the start of the next schedule
      let is_inside_schedule = false;
      let key_next_schedule = 0;
      for (let j = 0; j < day_schedules.length; j++) {
        const schedule = day_schedules[j];
        const start_hour = parseInt(schedule.startDate.split(":")[0]);
        const end_hour = parseInt(schedule.endDate.split(":")[0]);
        const now_hour = new Date().getHours();

        //If the current hour is less than the start hour of the schedule, we are outside the schedule. This is checked first to avoid the next if not necessary
        if (now_hour < start_hour) {
          key_next_schedule = j;
          break;
        }

        if (now_hour >= start_hour && now_hour < end_hour) {
          is_inside_schedule = true;
        }
      }

      if (!is_inside_schedule) {
        //Add the hours to the start of the next schedule
        const new_start_hour = new Date();
        new_start_hour.setHours(
          parseInt(day_schedules[key_next_schedule].startDate.split(":")[0]),
          parseInt(
            day_schedules[key_next_schedule].startDate
              .split(":")[1]
              .split(":")[0]
          ),
          0,
          0
        );
        new_start_hour.setHours(
          new_start_hour.getHours() +
            filters.outside_schedule_block.hours_to_block
        );
        day_schedules[
          key_next_schedule
        ].startDate = `${new_start_hour.getHours()}:${
          new_start_hour.getMinutes() === 0 ? "00" : new_start_hour.getMinutes()
        }:00`;
      }
    }

    /**
     * monday_block filter
     */
    //Check if the day is monday and if the filter is enabled and if today is saturday or sunday
    if (
      filters &&
      filters.monday_block.enabled &&
      day_of_week === 2 &&
      (today.getDay() === 6 || today.getDay() === 0) &&
      today.getDate() - date.getDate() <= 2
    ) {
      //Order the schedules by start hour
      day_schedules.sort((a, b) => {
        const a_hour = parseInt(a.startDate.split(":")[0]);
        const b_hour = parseInt(b.startDate.split(":")[0]);
        return a_hour - b_hour;
      });

      //If the day is monday, add the hours to the start of the next schedule
      const new_start_hour = new Date();
      new_start_hour.setHours(
        parseInt(day_schedules[0].startDate.split(":")[0]),
        parseInt(day_schedules[0].startDate.split(":")[1].split(":")[0]),
        0,
        0
      );
      day_schedules[0].startDate = `${new_start_hour.getHours()}:${
        new_start_hour.getMinutes() === 0 ? "00" : new_start_hour.getMinutes()
      }:00`;
    }

    // Loop through desired time resolution of the day
    for (
      let j = date_copy;
      j.getTime() <= date.getTime() + 24 * 60 * 60 * 1000;
      j.setMinutes(j.getMinutes() + 30)
    ) {
      //Check if date is in the past or check the appointment preparation block filter
      if (
        j.getTime() < new Date().getTime() ||
        (filters.appointment_preparation_block.enabled &&
          j.getTime() <
            new Date().getTime() +
              filters.appointment_preparation_block.hours_to_block *
                60 *
                60 *
                1000)
      ) {
        continue;
      }
      // Create a Date object with the current date and the current hour
      const hour = j;
      // If the current hour is available

      //If there is no schedule for that day, the hour is not available
      if (!day_schedules) {
        continue;
      }
      //Check if the hour is between one of the schedules  start and end hour come in string format 00:00:00
      let is_available = false;
      for (let k = 0; k < day_schedules.length; k++) {
        const schedule = day_schedules[k];
        const start_hour = new Date(
          hour.getFullYear(),
          hour.getMonth(),
          hour.getDate(),
          parseInt(schedule.startDate.split(":")[0]),
          parseInt(schedule.startDate.split(":")[1].split(":")[0]),
          0,
          0
        );
        const end_hour = new Date(
          hour.getFullYear(),
          hour.getMonth(),
          hour.getDate(),
          parseInt(schedule.endDate.split(":")[0]),
          parseInt(schedule.endDate.split(":")[1].split(":")[0]),
          0,
          0
        );

        if (
          hour.getTime() >= start_hour.getTime() &&
          hour.getTime() <= end_hour.getTime() - 60 * 60 * 1000
        ) {
          is_available = true;
          break;
        }
      }

      //check if the hour is between an appointment or before the duration of the appointment
      for (let k = 0; k < data.appointments?.length; k++) {
        const appointment = data.appointments[k];
        const appointment_start = new Date(+appointment.datetime);
        const appointment_end = new Date(
          +appointment.datetime + appointment.duration * 60 * 1000
        );
        if (
          hour.getTime() >=
            appointment_start.getTime() - appointment.duration * 60 * 1000 &&
          hour.getTime() < appointment_end.getTime() // fix the bug of the last hour BUG-288, qué sufrimento encontrarlo :(
        ) {
          is_available = false;
          break;
        }
      }

      //Check if the hour is between a timeBlock
      for (let k = 0; k < data.time_blocks?.length; k++) {
        const time_block = data.time_blocks[k];
        const time_block_start = new Date(+time_block.startDate);
        const time_block_end = new Date(+time_block.endDate);
        if (
          hour.getTime() >= time_block_start.getTime() &&
          hour.getTime() < time_block_end.getTime() // fix the this too, bug of the last hour BUG-288
        ) {
          is_available = false;
          break;
        }
      }

      if (!is_available) {
        continue;
      }

      available_hours.push({
        timestamp: hour.getTime(),
        //Redeable date format: 12:00
        redeable_hour: hour.toLocaleTimeString("es-ES", {
          hour: "2-digit",
          minute: "2-digit",
        }),
      });
    }
    // Add the array of available hours to the map
    map.set(date, available_hours);
  }

  return map;
};
