
export const getFormattedDate = (timestamp: string) => {
    const date = new Date(timestamp);
    const dateMinutes = date.getMinutes() === 0 ? '00' : date.getMinutes();
    const completeDayToShow = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + '  ' + date.getHours() + ' : ' + dateMinutes + 'h';

    return completeDayToShow;
}

export const getDM_HM = (timestamp: number) => {
    const date = new Date(timestamp);
    const dateMinutes = date.getMinutes() === 0 ? '00' : date.getMinutes();
    const month = (date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    const day = date.getDate() + '/' + month;
    const hour = date.getHours() + ':' + dateMinutes + 'h';
    // console.log(day, hour);
    return { day, hour };
}