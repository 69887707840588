import {
    actionsCountry as actions
} from "../actions/types";

const countryReducer = (state = null, action: any) => {
    switch (action.type) {
        case actions.SET_COUNTRY:
            return action.payload;
        default:
            return state;
    }
}

export default countryReducer;