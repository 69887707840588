import {
    actionsUser as actions
} from "./types";

export type User = {
    id?: string,
    firstName: string,
    lastName: string,
    email?: string,
    phoneNumber: string,
    city?: string,
    state?: string,
    postCode: string,
    country?: string,
    searchedAddress?: string,
    cords?: {
        lat?: number,
        lng?: number,
    }
    utms?: any,
}

export const setUser = (user: User) => {
    return {
        type: actions.SET_USER,
        payload: user,
    };
};

export const removeUser = () => {
    return {
        type: actions.REMOVE_USER
    };
};