import {
    actionsAppointment as actions
} from "../actions/types";

const appointmentReducer = (state = null, action: any) => {
    switch (action.type) {
        case actions.SET_APPOINTMENT:
            return action.payload;
        case actions.REMOVE_APPOINTMENT:
            return null;
        default:
            return state;
    }
}

export default appointmentReducer;